<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_fr">
          <el-button type="primary" icon="el-icon-plus" @click="add()">新增</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            tooltip-effect="dark"
            height="string"
            :default-expand-all="false"
            v-loading="loading">
            <el-table-column prop="menuName" align="left" label="名称"></el-table-column>
            <el-table-column prop="href" align="center" label="链接地址"></el-table-column>
            <el-table-column prop="component" align="center" label="组件路径"></el-table-column>
            <el-table-column prop="typeId" align="center" label="权限类型">
              <template slot-scope="scope">
                {{ scope.row.typeId | typeId }}
              </template>
            </el-table-column>
            <el-table-column prop="code" align="center" label="权限标识"></el-table-column>
            <el-table-column prop="status" align="center" label="是否禁用">
              <template slot-scope="scope">
                {{ scope.row.status | status }}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="120">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="text_Remove_Bgc" @click="del(scope)">
                  删除</el-button
                >
                <el-button type="text" size="small" class="text_Edit_Bgc" @click="edit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <Edit
      :isShow.sync="showEditDialog"
      :options="options"
      :listData="listData"
      :treeselectOptions="treeselectOptions"></Edit>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
export default {
  components: {
    Edit: () => import('./Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      options: {
        parentId: 0,
        typeId: 0,
        status: 1,
      },
      listData: [],
      tableData: [],
      treeselectOptions: [],
      loading: false,
      showEditDialog: false,
    }
  },
  created() {
    this.getData()
  },
  filters: {
    status: function (v) {
      return v == 1 ? '启用' : '禁用'
    },
    typeId: function (v) {
      return v == 1 ? '按钮' : '菜单'
    },
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  methods: {
    refresh() {
      this.showEditDialog = false
      this.getData()
    },

    add() {
      this.options = {
        parentId: 0,
        typeId: 0,
        status: 1,
      }
      this.showEditDialog = true
    },
    edit(row) {
      this.options = row.deepClone()
      this.showEditDialog = true
    },

    getData() {
      this.loading = true
      this.$api.menu
        .menusList()
        .then(res => {
          this.loading = false
          this.listData = res.data
          this.tableData = initTreeData(res.data)
          this.tableData.map((item, index) => {
            if (item.code == 'INDEX') {
              this.tableData.unshift(this.tableData.splice(index, 1)[0])
            }
          })
          this.treeselectOptions = [
            {
              id: 0,
              menuName: '顶级菜单',
              children: this.tableData,
            },
          ]
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.menu
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
